import { render, staticRenderFns } from "./header-detail.vue?vue&type=template&id=f29c0f38&"
import script from "./header-detail.vue?vue&type=script&lang=js&"
export * from "./header-detail.vue?vue&type=script&lang=js&"
import style0 from "./header-detail.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/builds/pagsmile/front-end/pagsmile-web/components/Header.vue').default})
