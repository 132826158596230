var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer-menu-v3 main"},[_c('h2',[_vm._v(_vm._s(_vm.$t(_vm.title)))]),_vm._v(" "),_c('ul',_vm._l((_vm.list),function(item,index){return _c('li',{key:item.label},[(item.isMenu)?_c('div',{staticClass:"footer-inner-menu-container"},[_c('div',{class:{
            'menu-label-container': true,
            'menu-hidden': _vm.hideInnerMenu[index]
          },on:{"click":function($event){return _vm.clickInnerMenu(index)}}},[_c('span',[_vm._v(_vm._s(_vm.$t(item.label)))]),_vm._v(" "),_c('span',{class:{ 'menu-arrow': true, up: _vm.hideInnerMenu[index] }})]),_vm._v(" "),_c('ul',{class:{
            'footer-menu-inner-menu': true,
            hidden: _vm.hideInnerMenu[index]
          }},_vm._l((item.items),function(innerItem){return _c('li',{key:innerItem.label},[(!innerItem.external && !innerItem.isSupport)?_c('nuxt-link',{attrs:{"to":_vm.localePath(innerItem.link || '')},nativeOn:{"click":function($event){return (function (ref) {
                    var id = ref.id; if ( id === void 0 ) id = index;

                    return _vm.clickInnerMenu(id);
          }).apply(null, arguments)}}},[_vm._v("\n              "+_vm._s(_vm.$t(innerItem.label) || '')+"\n              ")]):(!innerItem.isSupport)?_c('a',{attrs:{"href":innerItem.link || ''}},[_vm._v("\n              "+_vm._s(_vm.$t(innerItem.label) || '')+"\n              ")]):(innerItem.isSupport)?_c('button',{on:{"click":function($event){return _vm.openLiveChat($event)}}},[_vm._v("\n              "+_vm._s(_vm.$t(innerItem.label) || '')+"\n            ")]):_vm._e()],1)}),0)]):(item.isMedia)?_c('div',{staticClass:"media-content"},_vm._l((item.items),function(innerItem,innerIndex){return _c('a',{key:innerIndex,staticClass:"media-link",attrs:{"href":innerItem.link},on:{"mousemove":function($event){_vm.curIndex = innerIndex},"mouseout":function($event){_vm.curIndex = ''}}},[_c('b-img-lazy',{attrs:{"src":_vm.curIndex === innerIndex
                ? require('@/assets/img/footer/' +
                  innerItem.label +
                  innerIndex +
                  '.svg')
                : require('@/assets/img/footer/' + innerItem.label + '.svg'),"alt":"media"}})],1)}),0):(!item.external && !item.isSupport)?_c('nuxt-link',{attrs:{"to":_vm.localePath(item.link || '')}},[_vm._v("\n        "+_vm._s(_vm.$t(item.label) || '')+"\n        ")]):(!item.isSupport)?_c('a',{attrs:{"href":item.link || ''}},[_vm._v("\n        "+_vm._s(_vm.$t(item.label) || '')+"\n        ")]):(item.isSupport)?_c('button',{on:{"click":function($event){return _vm.openLiveChat($event)}}},[_vm._v("\n        "+_vm._s(_vm.$t(item.label) || '')+"\n      ")]):_vm._e()],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }