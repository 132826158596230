//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FooterMenu from '@/components/v3-components/footer-menu.vue'

export default {
  components: {
    FooterMenu
  },
  data () {
    return {
      currentDate: new Date(),
      products: {
        title: 'homepage-footer-products',
        list: [
          {
            link: '/solutions/payment-processing/',
            label: 'homepage-footer-products-link1'
          },
          {
            link: '/solutions/pagsmile-payout/',
            label: 'homepage-footer-products-link2'
          },
          {
            link: '/products-services/cross-border',
            label: 'homepage-footer-products-link3'
          },
          {
            link: '/',
            label: 'homepage-footer-products-link4'
          }
        ]
      },
      about: {
        title: 'homepage-footer-aboutus',
        list: [
          {
            link: '',
            label: 'homepage-footer-aboutus-link1',
            isSupport: true
          },
          {
            link: '/about-us',
            label: 'homepage-footer-aboutus-link2'
          },
          {
            link: '/about-us/partners',
            label: 'homepage-footer-aboutus-link3'
          },
          {
            link: '/about-us/careers',
            label: 'homepage-footer-aboutus-link4'
          },
          {
            link: '/about-us/events',
            label: 'homepage-footer-aboutus-link5'
          },
          {
            link: '/about-us/legal',
            label: 'homepage-footer-aboutus-link6'
          },
          {
            link: '/about-us/blog',
            label: 'homepage-footer-aboutus-link7'
          },
          {
            link: '/about-us/faq',
            label: 'homepage-footer-aboutus-link8'
          }
        ]
      },
      integrations: {
        title: 'homepage-footer-integration',
        list: [
          {
            link: 'https://docs.pagsmile.com/',
            label: 'homepage-footer-integration-link1',
            external: true
          }
        ]
      },
      socialMedia: {
        title: 'homepage-footer-social-media',
        list: [
          {
            isMedia: true,
            items: [
              {
                label: 'linkedin',
                link: 'https://www.linkedin.com/company/pagsmile/'
              },
              {
                label: 'insta',
                link: 'https://www.instagram.com/pagsmileglobal/'
              },
              {
                label: 'youtube',
                link: 'https://www.youtube.com/@pagsmileglobal'
              },
              {
                label: 'twitter',
                link: 'https://twitter.com/pagsmileglobal'
              }
            ]
          }
        ]
      },
      paymentMethods: {
        title: 'footer.payment-methods.title',
        list: [
          {
            isMenu: true,
            label: 'region-latam',
            items: [
              {
                link: '/products-services/installments',
                label: 'footer.payment-methods.installments'
              },
              {
                link: '/payment-methods/bank-transfer',
                label: 'footer.payment-methods.bank-transfer'
              },
              {
                link: '/payment-methods/boleto',
                label: 'footer.payment-methods.boleto'
              },
              {
                link: '/payment-methods/pix',
                label: 'footer.payment-methods.pix'
              },
              {
                link: '/payment-methods/br-credit-cards',
                label: 'footer.payment-methods.br-credit-cards'
              },
              {
                link: '/payment-methods/spei',
                label: 'footer.payment-methods.spei'
              },
              {
                link: '/payment-methods/safetypay',
                label: 'footer.payment-methods.safetypay'
              },
              {
                link: '/payment-methods/oxxo',
                label: 'footer.payment-methods.oxxo'
              },
              {
                link: '/payment-methods/via-baloto',
                label: 'footer.payment-methods.viabaloto'
              },
              {
                link: '/payment-methods/efecty',
                label: 'footer.payment-methods.efecty'
              },
              {
                link: '/payment-methods/tpaga',
                label: 'footer.payment-methods.tpaga'
              },
              {
                link: '/payment-methods/todito',
                label: 'footer.payment-methods.todito'
              },
              {
                link: '/payment-methods/pse',
                label: 'footer.payment-methods.pse'
              }
            ]
          },
          {
            isMenu: true,
            label: 'region-asia',
            items: [
              {
                link: '/payment-methods/paypay',
                label: 'paypay-page-title'
              },
              {
                link: '/payment-methods/alipayhk',
                label: 'alipayhk-page-title'
              },
              {
                link: '/payment-methods/dana',
                label: 'dana-page-title'
              },
              {
                link: '/payment-methods/tngo',
                label: 'tngo-page-title'
              },
              {
                link: '/payment-methods/gcash',
                label: 'gcash-page-title'
              },
              {
                link: '/payment-methods/truemoney',
                label: 'truemoney-page-title'
              },
              {
                link: '/payment-methods/kakaopay',
                label: 'kakaopay-page-title'
              },
              {
                link: '/payment-methods/boost',
                label: 'boost-page-title'
              },
              {
                link: '/payment-methods/pipay',
                label: 'pipay-page-title'
              },
              {
                link: '/payment-methods/paygo',
                label: 'paygo-page-title'
              }
            ]
          }
        ]
      },
      industries: {
        title: 'footer.industries.title',
        list: [
          {
            link: '/industry/global-companies',
            label: 'footer.industries.for-global-companies'
          },
          {
            link: '/industry/gaming',
            label: 'footer.industries.for-gaming'
          }
        ]
      },
      solutions: {
        title: 'homepage-footer-solutions',
        list1: [
          {
            link: '/solutions/marketing-localization/',
            label: 'homepage-footer-solutions-link1'
          },
          {
            link: '/solutions/recurring-payments/',
            label: 'homepage-footer-solutions-link2'
          },
          {
            link: '/industry/global-companies/',
            label: 'homepage-footer-solutions-link3'
          },
          {
            link: '/industry/gaming/',
            label: 'homepage-footer-solutions-link4'
          }
          // {
          //   link: '/crypto',
          //   label: 'homepage-footer-solutions-link5'
          // }
        ],
        list2: [
          {
            link: '/solutions/marketing-localization/',
            label: 'homepage-footer-solutions-link1'
          },
          {
            link: '/solutions/recurring-payments/',
            label: 'homepage-footer-solutions-link2'
          },
          {
            link: '/industry/global-companies/',
            label: 'homepage-footer-solutions-link3'
          },
          {
            link: '/industry/gaming/',
            label: 'homepage-footer-solutions-link4'
          }
        ]
      },
      markets: {
        title: 'homepage-footer-markets',
        list: [
          {
            link: '/markets/latin-america',
            label: 'homepage-footer-markets-link1'
          },
          {
            link: '/country-guides',
            label: 'homepage-footer-markets-link2'
          },
          {
            link: '/country-guides',
            label: 'homepage-footer-markets-link3'
          },
          {
            link: '/country-guides',
            label: 'homepage-footer-markets-link4'
          }
        ]
      }
    }
  },
  computed: {
    isCostaRica () {
      return /\/markets\/costaRica(?:\/(?=$))?$/i.test(this.$route.fullPath)
    }
  }
}
