//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'jquery/dist/jquery.min.js'
import { validationMixin } from 'vuelidate'
import {
  required,
  minLength,
  maxLength,
  email
} from 'vuelidate/lib/validators'
// import { getSign } from '@/plugins/utils'
import { v4 as uuidv4 } from 'uuid'
import { esaEncryptData } from '~/plugins/secret'

const corporateEmail = (value) => {
  const match = /^\w+[-.\w]*@(\w+[-.\w]*?\.\w{2,4})$/.exec(value)
  const forbiddenDomains = []
  // [
  //   'hotmail.com',
  //   'outlook.com',
  //   'gmail.com',
  //   'yahoo.com'
  // ]
  if (
    !match ||
    !match.length ||
    match.length < 2 ||
    forbiddenDomains.includes(match[1].toLowerCase())
  ) {
    return false
  }
  return true
}

export default {
  mixins: [validationMixin],
  data () {
    return {
      categoriesOpt: [
        {
          text: 'Cohecho a Funcionario Público Nacional o Extranjero',
          value: '1'
        },
        {
          text: 'Lavado de Activos',
          value: '2'
        },
        {
          text: 'Financiamiento al Terrorismo',
          value: '3'
        },
        {
          text: 'Negociación incompatible',
          value: '4'
        },
        {
          text: 'Administración desleal',
          value: '5'
        },
        {
          text: 'Delitos Informáticos',
          value: '6'
        },
        {
          text: 'Delitos Financieros',
          value: '7'
        },
        {
          text: 'Otros',
          value: '8'
        }
      ],
      relatedToOpt: [
        {
          text: 'No, es un caso nuevo',
          value: '1'
        },
        {
          text: 'Si, está relacionada al caso:',
          value: '2'
        }
      ],
      form: {
        relationship: '',
        fullname: '',
        email: '',
        categories: [],
        other: '',
        detail: '',
        relatedTo: '',
        case: ''
      },
      relationshipOpt: [
        {
          value: 'Empleado',
          displayName: 'Empleado'
        },
        {
          value: 'Tercero/proveedor',
          displayName: 'Tercero/proveedor'
        },
        {
          value: 'Mantener anónimo',
          displayName: 'Mantener anónimo'
        }
      ],
      errorMessage: '',
      submited: false,
      sendingData: false,
      fullnameStatus: false,
      emailStatus: false,
      formNumber: ''
    }
  },
  validations: {
    form: {
      relationship: {
        required,
        maxLength: maxLength(100)
      },
      fullname: {
        required,
        maxLength: maxLength(50)
      },
      email: {
        required,
        email,
        corporateEmail,
        maxLength: maxLength(255)
      },
      categories: {
        required,
        minLength: minLength(1)
      },
      other: {
        required,
        maxLength: maxLength(255)
      },
      detail: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(1500)
      },
      relatedTo: {
        required,
        minLength: minLength(1)
      },
      case: {
        required,
        maxLength: maxLength(255)
      }
    }
  },
  computed: {},
  mounted () {
    // this.$refs.ombudsman.show()
  },
  beforeUpdate () {},
  methods: {
    validateState (name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    shownForm () {
      this.submited = false
    },
    resetForm () {
      this.sendingData = false
      this.errorMessage = ''
      this.form = {
        relationship: '',
        fullname: '',
        email: '',
        categories: [],
        other: '',
        detail: '',
        relatedTo: '',
        case: ''
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    onSubmit () {
      const self = this
      this.errorMessage = ''
      this.$v.form.$touch()
      if (this.$v.form.relationship.$anyError || this.$v.form.categories.$anyError || this.$v.form.detail.$anyError || this.$v.form.relatedTo.$anyError) {
        this.errorMessage = this.$i18n.t('contact-us.field-error')
        return
      }
      if (!this.fullnameStatus && this.$v.form.fullname.$anyError) {
        this.errorMessage = this.$i18n.t('contact-us.field-error')
        return
      }
      if (!this.emailStatus && this.$v.form.email.$anyError) {
        this.errorMessage = this.$i18n.t('contact-us.field-error')
        return
      }
      // if (this.form.categories.length === 0) {
      //   this.errorMessage = this.$i18n.t('contact-us.field-error')
      //   return
      // }
      if (this.form.categories.includes('8') && this.$v.form.other.$anyError) {
        this.errorMessage = this.$i18n.t('contact-us.field-error')
        return
      }
      if (this.relatedTo === '2' && this.$v.form.case.$anyError) {
        this.errorMessage = this.$i18n.t('contact-us.field-error')
        return
      }
      const uuid = uuidv4().replace(/-/g, '')
      const pagsmileUrl = `${process.env.APIURL}/website/common/notification/whistleblower-mail`
      const { relationship, fullname, email, categories: categoriesValue, other, detail, relatedTo, case: caseValue } = this.form
      const data = {
        relationship,
        name: fullname,
        email,
        detail,
        endpoint: 'compliance'
      }
      if (data.name === '') { data.name = 'unkonwn' }
      if (data.email === '') { data.email = 'unkonwn' }
      const newCategories = []
      this.categoriesOpt.forEach(ca => {
        if (ca.value !== '8' && categoriesValue.includes(ca.value)) {
          newCategories.push(ca.text)
        }
      })
      if (categoriesValue.includes('8')) {
        newCategories.push(`Otros:${other}`)
      }
      data.categories = newCategories
      if (relatedTo === '2') {
        data.association = `está relacionada al caso:${caseValue}`
      } else {
        data.association = 'es un caso nuevo'
      }
      // console.log('data -> ', data)
      // // eslint-disable-next-line
      // return false
      const settings = {
        url: pagsmileUrl,
        method: 'POST',
        timeout: 0,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          key: this.$encrypt(uuid),
          data: esaEncryptData(JSON.stringify(data), uuid)
        })
      }
      this.sendingData = true
      $.ajax(settings)
        .done(function (response) {
          // console.log('response ===> ', response)
          self.sendingData = false
          if (response.code === '10000') {
            self.submited = true
            self.formNumber = response.data
            /* baidu analytics submit start */
            window._agl && window._agl.push(['track', ['success', { t: 3 }]])
            /* baidu analytics submit end */
            // self.$toast.success(response.msg, { timeout: 2000 })
            return true
          } else {
            console.log('Error: ', response.msg)
            self.$toast.error(response.msg)
          }
        })
        .catch((error) => {
          console.log('Error: ', error)
          self.sendingData = false
        })
    },
    handleSupport () {
      const SupportBtn = document.getElementsByClassName('support-btn')
      if (SupportBtn.length) {
        try {
          SupportBtn[0].click()
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error)
        }
      }
    },
    categoriesChange (categories) {
      if (!categories.includes('8')) {
        this.form.other = ''
      }
    }
  }
}
